@tailwind base;
@tailwind components;
@tailwind utilities;

.image-container {
    width: 100%;
    max-width: 624px;
    height: 0;
    padding-top: calc(548 / 624 * 100%); /* Aspect ratio 624:548 */
    position: relative;
    overflow: hidden;
    border-radius: 0.8rem;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.responsive-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

/* Adjust the container size for iPad devices (481px to 1024px) */
@media (min-width: 481px) and (max-width: 1024px) {
    .image-container {
        max-width: 100%;
        padding-top: calc(548 / 624 * 100%); /* Maintain original aspect ratio */
    }
}

/* Adjust the container size for smaller screens */
@media (max-width: 480px) {
    .image-container {
        max-width: 100%;
        padding-top: calc(548 / 624 * 100%); /* Maintain original aspect ratio */
    }
}

.section-restaurant .flex-wrap {
    flex-direction: column;
}

@media (min-width: 768px) {
    .section-restaurant .flex-wrap {
        flex-direction: row;
    }
}

@media (max-width: 767px) {
    .section-restaurant .flex-wrap {
        flex-direction: column;
    }

    .section-restaurant .order-1 {
        order: 1;
    }

    .section-restaurant .order-2 {
        order: 2;
    }
}
