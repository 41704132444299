@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom select */

/* Home-page */
.search-box .custom-select-container .custom-select-element:after {
  font-size: 18px;
}

.custom-select-container {
  color: #fafafa;
  display: flex !important;
  align-items: center !important;
  transition: all 0.2s ease-in;
  font-size: 14px;
}

.custom-select-element {
  display: inline-flex !important;
  align-items: center !important;
  position: relative;
  padding-right: 20px; /* Add padding to make space for the arrow */
}

.custom-select-element:after {
  content: "\ea4e"; 
  font-size: 17px;
  font-family: "remixicon";
  position: absolute;
  right: 3px; /* Position the arrow inside the box */
  pointer-events: none; /* Prevent the arrow from interfering with click events */
}

.custom-select-container:focus {
  outline: 2px;
}

.select-options {
  min-width: 150px;
  max-height: 200px;
  margin: 3px 0 0 0;
  padding: 5px !important;
  overflow-y: auto;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 20;
  list-style: none;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.07);
}

.select-options li {
  margin: 0;
  padding: 5px 5px 5px 10px !important;
  transition: all 0.15s ease-in;
  border-radius: 5px;
  font-size: 14px;
  color: #777;
}

.select-options li:hover {
  background: rgba(235, 88, 54, 0.1);
}

.select-options li[rel=hide] {
  display: none;
}

.select-options::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 0 0 5px 0;
}

.select-options::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #9e9e9e;
}
