@tailwind base;
@tailwind components;
@tailwind utilities;

/* Blog */
.section-blog .blog-card:hover figure:before {
  animation: shine 0.75s;
}
.section-blog .blog-card figure {
  border-radius: 15px;
  position: relative; /* Ensure the figure is positioned relative for the pseudo-element */
  overflow: hidden; /* Ensure the pseudo-element is clipped by the figure */
}
.section-blog .blog-card figure:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  transform: skewX(-25deg);
}

.lh-our-blog .lh-our-blog-categories ul li a:hover span {
  color: #676663;
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.lh-our-blog-instagram .lh-our-blog-instagram-image .lh-our-blog-instagram-image-inner {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
}
.lh-our-blog-instagram .lh-our-blog-instagram-image .lh-our-blog-instagram-image-inner a {
  position: relative;
}
.lh-our-blog-instagram .lh-our-blog-instagram-image .lh-our-blog-instagram-image-inner a:hover:after,
.lh-our-blog-instagram .lh-our-blog-instagram-image .lh-our-blog-instagram-image-inner a:hover:before {
  opacity: 1;
}
.lh-our-blog-instagram .lh-our-blog-instagram-image .lh-our-blog-instagram-image-inner a:after {
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  opacity: 0;
}
.lh-our-blog-instagram .lh-our-blog-instagram-image .lh-our-blog-instagram-image-inner a:before {
  transition: all 0.3s ease-in-out;
  content: "\ee66";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: remixicon !important;
  color: #fafafa;
  z-index: 5;
  font-size: 20px;
  font-weight: 300;
  opacity: 0;
}

/* Responsive Enhancements */
@media (max-width: 768px) {
  .section-blog .blog-card figure {
    border-radius: 10px;
  }
  .lh-our-blog-instagram .lh-our-blog-instagram-image .lh-our-blog-instagram-image-inner {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
  .lh-our-blog-instagram .lh-our-blog-instagram-image .lh-our-blog-instagram-image-inner a:before {
    font-size: 16px;
  }
}
