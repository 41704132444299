@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hero text animations */
@keyframes slideUpFadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}
.animate-slideUpFadeIn {
  animation: slideUpFadeIn 0.5s ease-in-out forwards;
  animation-fill-mode: both;
}
.delay-500ms {
  animation-delay: 0.5s;
}
.delay-1000ms {
  animation-delay: 2s;
}
.delay-1500ms {
  animation-delay: 4s;
}
.delay-2000ms {
  animation-delay: 5.5s;
}
.delay-2500ms {
  animation-delay: 6s;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

.typing-container {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
  animation: fadeIn 0.1s ease-in-out forwards, typing 2.5s steps(30, end) forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.delayed-fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards, bounce 2s infinite;
  animation-delay: 3s, 4s; /* Adjust the delay as needed */
}

/* Hero section adjustments */
#hero {
  height: 100vh; /* Full viewport height */
  position: relative;
  overflow: hidden; /* Prevent scroll interference */
}

.hero-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-position: center;
}

.hero-content {
  position: relative;
  z-index: 10;
  text-align: left;
  color: #fafafa;
  padding: 0 12px;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.3;
}

.delayed-fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards, bounce 2s infinite;
  animation-delay: 3s, 4s; /* Adjust the delay as needed */
}
