@tailwind base;
@tailwind components;
@tailwind utilities;

/* Basic Navbar */
.basic-nav {
  background-color: #969591;
  z-index: 1000;
}

.language-form {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

/* Header */
ul.navbar-nav li.nav-item a.dropdown-toggle:after {
  display: none;
}
ul.navbar-nav li.nav-item a.nav-link:hover {
  color: #676663;
}
ul.navbar-nav li.nav-item a.nav-link:hover::before {
  background: #676663;
  width: 100%;
}
ul.navbar-nav li.nav-item a.nav-link::before {
  content: "";
  position: absolute;
  top: auto;
  bottom: -10px;
  height: 2px;
  width: 0;
  background: #676663;
  left: 0;
  right: auto;
  transition: all 0.3s ease 0s;
  margin: 0 auto;
}
ul.navbar-nav .dropdown .dropdown-menu {
  position: absolute;
  left: auto !important;
}
ul.navbar-nav .dropdown .dropdown-menu li a {
  background-color: #a8a29e;
}
ul.navbar-nav .dropdown:hover .dropdown-menu {
  opacity: 1 !important;
  visibility: visible !important;
  margin-top: 9px !important;
}

.header-fixed {
  width: 100%;
  padding: 0 !important;
  position: fixed !important;
  top: 0 !important;
  background-color: rgba(168, 162, 158, 0.9) !important;
  animation: slideDown 0.35s ease-in-out;
  z-index: 100;
}
.header-fixed nav {
  padding: 9px 0;
}

.lh-location .custom-select .custom-select::after {
  right: -10px !important;
  line-height: 1.6;
}
.lh-location .custom-select .select-options {
  margin-top: 10px !important;
  left: -18px;
}

/* Mobile-menu */
.lh-side-cart .lh-menu-inner .lh-menu-box::placeholder {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 300;
  color: #777;
}
.lh-side-cart .lh-menu-inner ul li .menu-toggle {
  width: 30px;
  height: 48px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
}
.lh-side-cart .lh-menu-inner ul li .menu-toggle::before {
  content: "+";
  font-size: 22px;
  font-weight: 400;
  color: #777;
}
.lh-side-cart .lh-menu-inner ul li .menu-toggle i {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #444;
  transition: all 0.5s ease 0s;
}
.lh-side-cart .lh-menu-inner ul li .sub-menu li a:hover {
  opacity: 1;
}

.lh-menu-open {
  left: 0 !important;
}

@media (min-width: 1024px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-collapse .collapse:not(.show) {
    display: block;
  }
  .collapse {
    visibility: inherit !important;
  }
}

/* Custom styles for the hamburger menu */
.menu-button-wrapper {
  width: 65px;
  height: 45px;
  cursor: pointer;
  margin: 0;
}

.menu-button {
  width: 100%;
  height: 100%;
}

.menu-line {
  transition: background 0.2s ease 0.4s;
  display: block;
  width: 100%;
  height: 2px;
  background: #676663;
  transform-origin: 50% 50%;
  width: 60%;
  margin: 0 20%;
  position: relative;
  top: 22px;
}

.menu-line::before,
.menu-line::after {
  display: block;
  width: 100%;
  height: 2px;
  background: #676663;
  position: absolute;
  left: 0;
  content: '';
  transition: transform 0.2s ease 0s, width 0.2s ease 0.2s, top 0.2s ease 0.4s;
  transform-origin: 0 50%;
}

.menu-line::before {
  top: -10px;
}

.menu-line::after {
  top: 10px;
}

.menu-open .menu-line {
  transition: background 0.2s ease 0s;
}

.menu-open .menu-line::before,
.menu-open .menu-line::after {
  transition: top 0.2s ease 0s, width 0.2s ease 0.2s, transform 0.2s ease 0.4s;
  top: 0;
  width: 50%;
}

.menu-open .menu-line::before {
  transform: rotate(45deg);
}

.menu-open .menu-line::after {
  transform: rotate(-45deg);
}

/* Additional styles for the menu */
.lh-sidebar-overlay {
  display: none;
}

.lh-menu-open .lh-sidebar-overlay {
  display: block;
}

.lh-mobile-menu {
  transition: all 0.5s ease;
  left: -300px; /* Hidden state */
}

.lh-menu-open .lh-mobile-menu {
  left: 0; /* Visible state */
}

.toggle-switch {
  position: relative;
  display: inline-flex; /* Change this to inline-flex for better alignment */
  width: 60px;
  height: 30px;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: rgba(103, 102, 99, 0.2);
}

input:checked + .slider:before {
  transform: translateX(30px);
}

.switch-labels {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #fff;
  padding: 0 10px;
  pointer-events: none;
}