@tailwind base;
@tailwind components;
@tailwind utilities;

/* Loader */
.lh-loader .loader::after, .lh-loader .loader::before {
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 50px solid #676663;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-animation: animloader14 2s linear infinite;
          animation: animloader14 2s linear infinite;
}
.lh-loader .loader::after {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
@-webkit-keyframes animloader14 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}
@keyframes animloader14 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

.bg-size-full::before {
  background-size: 100%;
}
.bg-size-full::after {
  background-size: 100%;
}

/* Ensure this CSS does not hide the functional select element */
.hide-select {
  display: none; /* Ensure this is applied correctly if needed */
}
