@tailwind base;
@tailwind components;
@tailwind utilities;

.lh-amenities-in .amenities-contain a:hover i {
  margin-left: 5px;
}

.amenities-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-radius: 15px; /* Border radius for the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for the container */
  overflow: hidden; /* Hide overflow to ensure the image fits within the rounded corners */
}

.amenities-box picture,
.amenities-box img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image fills the area while maintaining its aspect ratio */
}

@media (min-width: 1280px) {
  .amenities-box {
    width: 50%;
    padding: 0;
    border-radius: 0; /* No border radius for larger screens */
  }

  .amenities-box img {
    border-radius: 0;
  }
}
