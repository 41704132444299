@tailwind base;
@tailwind components;
@tailwind utilities;

.room-button {
  border: none;
  background: 0 0;
  padding: 0;
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.room-button img {
  display: block;
  width: 100%; /* Ensure the image takes full width */
  height: auto; /* Maintain aspect ratio */
}

.button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .8);
  border-radius: 50%;
  transition: all .3s ease;
}

.room-button:hover .button-icon {
  bottom: 0;
  right: 0;
}

.room-button i {
  color: #fff;
  font-size: 25px;
}

.room-img .room-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media screen and (max-width: 1279px) {
  .room-img {
    padding: 0 24px 24px 24px;
  }

  .room-img .room-image {
    border-radius: 15px !important;
  }
}

@media screen and (max-width: 991px) {
  .tab-content {
    margin-top: 0;
  }

  .lh-room button {
    font-size: 18px;
  }

  .nav-tabs.rooms .nav-link {
    width: 50%;
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 767px) {
  .lh-room button {
    font-size: 16px;
  }
}

@media screen and (max-width: 575px) {
  .lh-room-contain .lh-contain-heading {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .lh-room-contain .lh-contain-heading .lh-room-price {
    margin-top: 15px;
  }

  .lh-room-contain .lh-main-features .lh-room-features {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .lh-room-contain .lh-main-features .lh-room-features .lh-cols-room:last-child {
    padding-top: 15px;
    padding-left: 0;
  }
}

@media screen and (max-width: 360px) {
  .nav-tabs.rooms .nav-link {
    width: 100%;
  }
}
