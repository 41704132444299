@tailwind base;
@tailwind components;
@tailwind utilities;

.section-gallery {
    @apply py-[100px] xl:py-[70px];
}

.card-columns {
    @apply columns-2 gap-[24px] lg:columns-4 max-[480px]:columns-1 w-full;
}

.magnific-img {
    @apply mb-[24px] mt-0 ml-0 mr-0 p-0 rounded-[15px] truncate relative shadow-md;
}

@media (min-width: 768px) {
    .section-gallery {
        @apply xl:py-[100px];
    }
}

.nav-tabs {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
  width: 100%
}

.nav-tabs .nav-link {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 25%;
  padding: 0;
  margin: 0;
  text-align: center;
  box-sizing: border-box;
  min-width: 55px !important;
  background-color: #e4e3e1;
  backdrop-filter: blur(5px)
}

.nav-tabs .nav-link span {
  flex-grow: 1;
  text-align: center
}

.nav-tabs .nav-link img {
  display: block;
  height: 75;
  object-fit: contain
}

.nav-tabs .nav-link i {
  font-size: 24px;
  display: none
}

.nav-tabs.rooms .nav-link.active {
  background-color: rgba(228, 227, 225, 0);
  border: 4px solid #e4e3e1
}

@media (max-width:600px) {
  .room-button img {
      display: block !important
  }

  .center-button {
      display: flex !important
  }

  .nav-tabs .nav-link picture,
  .nav-tabs .nav-link span,
  .room-button img {
      display: none
  }

  .nav-tabs {
      justify-content: space-between;
      overflow-x: auto;
      padding: 0 10px
  }

  .nav-tabs .nav-link {
      width: 25%;
      padding: 10px 0;
      margin: 0 5px
  }

  .nav-tabs .nav-link i {
      display: block;
      font-size: 24px
  }
}
