@tailwind base;
@tailwind components;
@tailwind utilities;

/* Common Styles */
body {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  color: #777;
  line-height: 26px !important;
  letter-spacing: 0.02rem;
  background-color: #d0cfcb;
  overflow-x: hidden;
}

/* Prevent overflow for all elements */
* {
  box-sizing: border-box;
}

/* Apply background images to the body */
body::before, body::after {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  width: 45%; /* Adjust as needed */
  background-repeat: no-repeat;
  background-size: contain; /* Adjust as needed */
  opacity: 0.15;
  z-index: -1; /* Ensure it's behind the content */
  pointer-events: none; /* Ensure it doesn't interfere with interactions */
  display: block; /* Ensure they are rendered */
}

body::before {
  left: 1rem;
  background-image: url("/static/dist/img/background/BackgroundLeft.svg");
  background-position: left bottom;
}

body::after {
  right: 1rem;
  width: 20%;
  background-image: url("/static/dist/img/background/BackgroundRight.svg");
  background-position: right bottom;
}

/* Media query for phones */
@media (max-width: 767px) {
  body::before {
    display: none; /* Hide the left background image */
  }

  body::after {
    left: auto;
    right: 0.2rem;
    bottom: 0;
    width: 70%; 
    height: auto;
    background-size: contain; 
    background-position: right bottom; 
    opacity: 0.15; 
  }
}

/* Ensure the hero section is positioned relative */
#hero {
  position: relative;
  z-index: 1; /* Ensure it's above the background images */
}

/* Ensure hero-image dimensions */
.hero-image {
  background-size: cover;
  background-position: bottom;
  background-blend-mode: overlay;
  transition: transform 0.5s ease;
  width: 100%; /* Ensure width is set */
  height: 300px; /* Set a fixed height */
  overflow-x: hidden;
}

@layer utilities {
  .delay-500ms {
    animation-delay: 0.5s;
  }
  .delay-1000ms {
    animation-delay: 1s;
  }
}

/* Text selection styles */
::-moz-selection {
  color: #676663;
  background: rgba(237, 90, 49, 0.15);
}

::selection {
  color: #676663;
  background: rgba(237, 90, 49, 0.15);
}

.font-Roboto {
  font-family: "Roboto";
}

.font-Poppins {
  font-family: "Poppins";
}

/* Word spacing utility */
.wordspac_15 {
  word-spacing: 15px;
}

/* Paragraph, list, and anchor tag styles */
p, li, a {
  margin-bottom: 0;
  font-family: "Roboto";
  font-size: 15px;
  color: #777;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.03rem;
}

/* Heading styles */
h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins";
  color: #fafafa;
  margin-bottom: 0;
  letter-spacing: 0.03rem;
}

/* Ensure sections do not overflow */
section {
  overflow-x: hidden;
}

/* Custom class for SweetAlert2 popup */
.swal2-popup.swal2-popup-custom {
  padding: 2em !important; /* Ensure proper padding */
  text-align: left !important; /* Align text to the left */
}

.swal2-popup .swal2-icon {
  width: auto !important; /* Adjust width */
  height: auto !important; /* Adjust height */
  margin: 0 !important; /* Remove any margins */
  padding: 0 !important; /* Remove any paddings */
  border-color: #fff!important;
}

.swal2-popup .swal2-icon img {
  width: 80px !important; /* Set desired width */
  height: 80px !important; /* Set desired height */
  border-radius: 0 !important; /* Remove any border radius */
}

.swal2-title, .swal2-content {
  font-size: 1.2em !important; /* Adjust font size if necessary */
  display: block !important; /* Ensure elements are displayed */
}

.swal2-title {
  font-size: 1.5em !important; /* Adjust title font size for better visibility */
  font-weight: bold !important; /* Make title bold */
  margin-bottom: 0.5em !important; /* Add margin below the title */
}

.swal2-content h2, 
.swal2-content h3, 
.swal2-content p, 
.swal2-content ul, 
.swal2-content li {
  font-size: 1em !important; /* Adjust font size for sub-elements */
  margin: 0.5em 0 !important; /* Add margin to sub-elements for better readability */
  display: block !important; /* Ensure elements are displayed */
}

.swal2-content ul {
  list-style-type: disc !important; /* Display list items with bullets */
  padding-left: 1.5em !important; /* Add padding to the left for list items */
}

.swal2-content li {
  margin-bottom: 0.5em !important; /* Add margin to list items for better spacing */
}

.swal2-popup {
  max-width: 600px !important; /* Adjust popup width if necessary */
  font-family: Arial, sans-serif !important; /* Ensure a readable font is used */
  text-align: left !important; /* Ensure text alignment is left */
}


.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
