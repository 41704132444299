@tailwind base;
@tailwind components;
@tailwind utilities;

/* Image container for maintaining aspect ratio */
.image-container {
    width: 100%;
    max-width: 624px;
    height: 0;
    padding-top: calc(548 / 624 * 100%); /* Aspect ratio 624:548 */
    position: relative;
    overflow: hidden;
    border-radius: 0.8rem;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.responsive-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.8rem;
}

/* Adjust the container size for iPad devices (481px to 1024px) */
@media (min-width: 481px) and (max-width: 1024px) {
    .image-container {
        max-width: 100%;
        padding-top: calc(548 / 624 * 100%); /* Maintain original aspect ratio */
    }
}

/* Adjust the container size for smaller screens */
@media (max-width: 480px) {
    .image-container {
        max-width: 100%;
        padding-top: calc(548 / 624 * 100%); /* Maintain original aspect ratio */
    }
}

.room-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0.8rem;
}

.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; /* Ensure the container takes full height if needed */
}

/* Additional styles for the .nav-tabs */
.nav-tabs.rooms {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Ensure buttons wrap on smaller screens */
  gap: 10px; /* Add spacing between buttons */
}

.nav-tabs.rooms .nav-link {
  flex: 1; /* Ensure buttons are evenly spaced */
  min-width: 100px; /* Minimum width for buttons */
  text-align: center; /* Center align text in buttons */
  padding: 10px 20px; /* Add padding for better click area */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

/* Hover and active state for better UX */
.nav-tabs.rooms .nav-link:hover, 
.nav-tabs.rooms .nav-link.active {
  background-color: #f0f0f0; /* Change background color on hover/active */
}