@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tap To Top */
.tap-to-top {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  position: fixed;
  bottom: 2rem;
  right: 20px;
  background-color: #000;
  color: #fafafa;
  border-radius: 5px;
  text-align: center;
  z-index: 1000;
  cursor: pointer;
}

.tap-to-top i {
  font-size: 20px; /* Ensure the icon size */
}

@media (max-width: 600px) {
  .tap-to-top {
      display: flex; /* Ensure it is displayed and centered on mobile */
      bottom: 6rem;
  }
}

/* Ensure the bounce effect is smooth */
.hover\\:animate-bounce {
  animation: bounce 1s infinite;
}

/* Tap to top */
.tap-to-top i:hover {
  animation: moveUp 1s ease-in-out infinite;
}
@keyframes moveUp {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-5px);
  }
}