@tailwind base;
@tailwind components;
@tailwind utilities;

.room-button {
    border: none;
    background: 0 0;
    padding: 0;
    cursor: pointer;
    position: relative;
    display: block;
    width: 100%;
    height: 100%
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .2);
    transition: opacity .3s ease
}
.room-img:hover .overlay {
    opacity: 0
}
.button-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .8);
    border-radius: 50%;
    transition: all .3s ease
}
.room-button:hover .button-icon {
    bottom: 0;
    right: 0
}
.room-button i {
    box-shadow: 0 4px 8px rgba(0, 0, 0, .5);
    color: rgba(0, 0, 0, .5);
    font-size: 25px
}
.center-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    background-color: transparent;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fafafa;
    font-size: 25px;
    padding: 10px 20px;
    transition: all .3s ease;
    opacity: 1;
    border: 2px solid #fafafa
}
.room-img:hover .center-button {
    opacity: 1
}
.nav-tabs {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 0;
    margin: 0;
    width: 100%
}
.nav-tabs .nav-link {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 25%;
    padding: 0;
    margin: 0;
    text-align: center;
    box-sizing: border-box;
    min-width: 55px !important;
    background-color: #e4e3e1;
    backdrop-filter: blur(5px)
}
.nav-tabs .nav-link span {
    flex-grow: 1;
    text-align: center
}
.nav-tabs .nav-link img {
    display: block;
    height: 75;
    object-fit: contain
}
.nav-tabs .nav-link i {
    font-size: 24px;
    display: none
}
.nav-tabs.rooms .nav-link.active {
    background-color: rgba(228, 227, 225, 0);
    border: 4px solid #e4e3e1
}
@media (max-width:600px) {
    .room-button img {
        display: block !important
    }
    .center-button {
        display: flex !important
    }
    .nav-tabs .nav-link picture,
    .nav-tabs .nav-link span,
    .room-button img {
        display: none
    }
    .nav-tabs {
        justify-content: space-between;
        overflow-x: auto;
        padding: 0 10px
    }
    .nav-tabs .nav-link {
        width: 25%;
        padding: 10px 0;
        margin: 0 5px
    }
    .nav-tabs .nav-link i {
        display: block;
        font-size: 24px
    }
}