@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --custom-g1: rgb(98, 0, 234);
    --custom-g2: rgb(236, 64, 122);
    --custom-g3: rgb(255, 165, 0);
    --custom-g4: rgb(34, 193, 195);
    --custom-g5: rgb(253, 187, 45);
    --custom-tile-size: 50px; /* Default tile size */
}

.custom-hero-section {
    background: linear-gradient(to right, var(--custom-g1), var(--custom-g2), var(--custom-g3), var(--custom-g4), var(--custom-g5), var(--custom-g1));
    background-size: 200%;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 0;
    z-index: 1; /* Ensure the gradient is layered correctly */
}

.custom-hero-section.custom-toggled {
    animation: none;
}

.custom-hero-section.custom-toggled #custom-title {
    opacity: 0;
}

.custom-hero-section.custom-toggled #custom-icon {
    opacity: 0.7;
}

.custom-hero-section.custom-toggled #custom-tiles .custom-tile:hover {
    opacity: 0.7 !important;
}

.custom-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

#custom-tiles {
    height: calc(100vh - 1px);
    width: calc(100vw - 1px);
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--custom-tile-size));
    grid-template-rows: repeat(auto-fill, var(--custom-tile-size));
    position: relative;
    z-index: 2;
    border: 0.5px solid rgba(255, 255, 255, 0.3);
}

.custom-tile {
    width: var(--custom-tile-size);
    height: var(--custom-tile-size);
    position: relative;
    cursor: pointer;
    opacity: 0.7;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

#custom-title {
    width: 50vw;
    color: white;
    font-family: "Rubik", sans-serif;
    font-size: 10vw;
    margin: 0;
    pointer-events: none;
    transition: opacity 1200ms ease;
    z-index: 3;
}

#custom-title .custom-fancy {
    color: var(--custom-g2);
    font-family: 'Roboto Mono', monospace;
    font-size: 1.5em;
    line-height: 0.9em;
}

#custom-icon {
    color: rgba(255, 255, 255, 0.15);
    font-size: 80vmin;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1200ms ease;
    z-index: 1;
}

.custom-meta-link {
    position: fixed;
    left: 10px;
    bottom: 10px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    cursor: pointer;
    transition: background-color 400ms, border-color 400ms;
    z-index: 10000;
}

.custom-meta-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.2);
}

.custom-meta-link > i,
.custom-meta-link > span {
    height: 20px;
    line-height: 20px;
}

.custom-meta-link > span {
    color: white;
    font-family: "Rubik", sans-serif;
    transition: color 400ms;
}

#custom-source-link {
    bottom: 60px;
}

#custom-source-link > i {
    color: rgb(94, 106, 210);
}

#custom-yt-link > i {
    color: rgb(239, 83, 80);
}
