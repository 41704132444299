@tailwind base;
@tailwind components;
@tailwind utilities;

/* Faq */
.ld-faq {
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.ld-faq .accordion .accordion-item:not(:nth-child(1)) {
  margin-top: 10px;
}

.ld-faq .accordion .accordion-item .accordion-button {
  background-color: #e4e3e1;
  color: #000;
  padding: 15px;
  font-size: 16px;
}

.ld-faq .accordion .accordion-item .accordion-button::after {
  background: none;
  font-family: "remixicon";
  content: "\ea4e";
  font-size: 20px;
  line-height: 18px;
}

.ld-faq .accordion .accordion-item .accordion-button:not(.collapsed)::after {
  background: none;
  font-family: "remixicon";
  content: "\ea4e";
  font-size: 20px;
  color: #676663;
  line-height: 18px;
}

.ld-faq .accordion .accordion-item .accordion-collapse {
  border-radius: 10px;
  visibility: visible;
  padding: 15px;
}

/* Responsive Enhancements */
@media (max-width: 768px) {
  .ld-faq {
    padding: 10px;
  }
  .ld-faq .accordion .accordion-item .accordion-button {
    padding: 10px;
    font-size: 14px;
  }
  .ld-faq .accordion .accordion-item .accordion-collapse {
    padding: 10px;
  }
}
